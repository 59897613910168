'use client'

import { Fragment, ReactElement } from 'react'
import { noop } from 'lodash'

import { Cell, Divider } from '@vinted/web-ui'

import { CreditCardModel } from 'types/models'

import CreditCard from './CreditCard'
import { ClickableCreditCardItemType } from './types'

type Props = {
  onItemClick?: (card: CreditCardModel) => void
  cards: Array<CreditCardModel>
  clickableItemType: ClickableCreditCardItemType
  renderSuffix: (cardId: string) => ReactElement
}

const CreditCardList = ({ cards, renderSuffix, clickableItemType, onItemClick = noop }: Props) => {
  return (
    <Cell testId="credit-card-list" styling={Cell.Styling.Tight}>
      {cards.map(card => (
        <Fragment key={card.id}>
          <CreditCard
            card={card}
            suffix={renderSuffix(card.id)}
            onClick={onItemClick}
            clickableType={clickableItemType}
          />
          <Divider />
        </Fragment>
      ))}
    </Cell>
  )
}

export default CreditCardList
