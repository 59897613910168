import ApiClient from 'libs/common/api-client'

import { CreditCardRegistrationPrepareModel } from 'types/models'

import { CreditCard } from '../types'
import { TokenizeResult, TokenizeStatus } from './types'

type CheckoutComGetTokenResponse = {
  token: string
}

function load() {
  // Checkout.com does not require loading of resources
}

async function tokenize(
  card: CreditCard,
  registrationPrepare: CreditCardRegistrationPrepareModel,
): Promise<TokenizeResult> {
  if (!registrationPrepare.cardRegistrationUrl) {
    return Promise.resolve({ status: TokenizeStatus.LibraryNotLoaded })
  }

  const api = new ApiClient()

  // https://api-reference.checkout.com/#operation/requestAToken
  const response = await api.post<CheckoutComGetTokenResponse>(
    registrationPrepare.cardRegistrationUrl,
    {
      type: 'card',
      name: card.name,
      number: card.cardNumber,
      cvv: card.cvc.toString(),
      expiry_year: card.expirationYear,
      expiry_month: card.expirationMonth,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${registrationPrepare.accessKey}`,
      },
    },
  )

  if ('errors' in response) {
    return Promise.resolve({
      status: TokenizeStatus.ProviderError,
      error: JSON.stringify(response.errors),
    })
  }

  return Promise.resolve({ status: TokenizeStatus.Ok, encryptedCard: response.token })
}

export default {
  load,
  tokenize,
}
