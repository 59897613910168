type LoadStylesheetProps = {
  id: string
  href: string
  crossOrigin?: string
  integrity?: string
}

const loadStylesheet = ({ id, href, crossOrigin, integrity }: LoadStylesheetProps) => {
  return new Promise((resolve, reject) => {
    const stylesheet = document.createElement('link')

    stylesheet.id = id
    stylesheet.href = href
    stylesheet.type = 'text/css'
    stylesheet.rel = 'stylesheet'
    if (crossOrigin) stylesheet.crossOrigin = crossOrigin
    if (integrity) stylesheet.integrity = integrity
    stylesheet.onload = resolve
    stylesheet.onerror = reject

    document.head.appendChild(stylesheet)
  })
}

const removeStylesheet = (id: string) => {
  const stylesheet = document.getElementById(id)

  if (stylesheet) document.head.removeChild(stylesheet)
}

export { loadStylesheet, removeStylesheet }
