import { ExtraServiceOrderType } from 'constants/extra-service'

export enum Field {
  Name = 'name',
  CardNumber = 'cardNumber',
  ExpirationDate = 'expirationDate',
  CVC = 'cvc',
  PostalCode = 'postalCode',
  IsSingleUse = 'isSingleUse',
}

export type CreditCard = {
  brand: string
  name: string
  cardNumber: string
  cvc: string
  expirationYear: number
  expirationMonth: number
  postalCode?: string
}

export type FormModel = {
  [Field.Name]: string
  [Field.CardNumber]: string
  [Field.CVC]: string
  [Field.PostalCode]: string
  [Field.ExpirationDate]: string
  [Field.IsSingleUse]: boolean
}

export type TrackableField = {
  name: string
  isValid: boolean
}

type EscrowCreditCardAddTrackingDetails = {
  type: 'escrow'
  transaction_id: number
}

type SettingsCreditCardAddTrackingDetails = {
  type: 'settings'
}

type ExtraServiceCreditCardAddTrackingDetails = {
  type: ExtraServiceOrderType
  service_order_id: number
}

type SingleCheckoutCreditCardAddTrackingDetails = {
  type: 'single_checkout'
  checkout_id: string
}

export type CreditCardAddTrackingDetails =
  | EscrowCreditCardAddTrackingDetails
  | SettingsCreditCardAddTrackingDetails
  | ExtraServiceCreditCardAddTrackingDetails
  | SingleCheckoutCreditCardAddTrackingDetails
