/**
 * This is a custom Adyen await component for blik payment method (blik_direct).
 * Adyen drop-in component library for web does not allow to customize `Await` component
 * mounted to a checkout flow. However, it is allowed to do so in iOS and android SDKs.
 * To overcome this limitation on web and have a proper Vinted UI, this component is implemented.
 * It achieves a custom UI by mounting `Await` component of Adyen, but hiding it. This way
 * `Await` component still does its job like polling Adyen servers etc, but lets Vinted to
 * have a custom UI for it.
 *
 * In case of Blik, Adyen `await` component does not require member input, but just displays
 * a note that member needs to open his Blik app and confirm a payment there.
 * Hence this is exactly what this custom component does as well.
 *
 * You can see Adyen blik component implementation and usage of `Await` component inside of it here:
 * https://github.com/Adyen/adyen-web/blob/master/packages/lib/src/components/Blik/Blik.tsx
 */

'use client'

import { Cell, Text, Icon, Spacer } from '@vinted/web-ui'
import { Blik32 } from '@vinted/payment-icons'

import useTranslate from 'hooks/useTranslate'

import { AdyenCheckout, AdyenAuthAction } from 'types/adyen'

import AdyenAwait from './AdyenAwait'

type Props = {
  adyenCheckout: AdyenCheckout
  adyenAction: AdyenAuthAction
}

const AdyenBlikAwait = ({ adyenAction, adyenCheckout }: Props) => {
  const translate = useTranslate('blik_direct_payment_method.pending_payment_modal')

  return (
    <>
      <div hidden>
        <AdyenAwait adyenAction={adyenAction} adyenCheckout={adyenCheckout} />
      </div>
      <Cell>
        <div className="u-text-center">
          <Icon name={Blik32} />
          <Spacer size={Spacer.Size.XSmall} />
          <Text
            type={Text.Type.Heading}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
          <Spacer size={Spacer.Size.Large} />
        </div>
        <Text html type={Text.Type.Body} text={translate('description')} />
      </Cell>
    </>
  )
}

export default AdyenBlikAwait
