import { CreditCardConfigurationDto } from 'types/dtos'
import { CreditCardConfigurationModel } from 'types/models'

export const transformCreditCardConfigurationDto = ({
  type,
  name,
  card_number_patterns,
  card_number_format,
  card_number_min_length,
  card_number_max_length,
  card_number_luhn,
  cvv_required,
  cvv_min_length,
  cvv_max_length,
  icon_url,
}: CreditCardConfigurationDto): CreditCardConfigurationModel => ({
  type,
  name,
  cardNumberPatterns: card_number_patterns,
  cardNumberFormat: card_number_format,
  cardNumberMinLength: card_number_min_length,
  cardNumberMaxLength: card_number_max_length,
  cardNumberLuhn: card_number_luhn,
  cvvRequired: cvv_required,
  cvvMinLength: cvv_min_length,
  cvvMaxLength: cvv_max_length,
  iconUrl: icon_url,
})

export const transformCreditCardConfigurationDtos = (dtos: Array<CreditCardConfigurationDto>) =>
  dtos.map(transformCreditCardConfigurationDto)
