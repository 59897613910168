import { useCallback } from 'react'
import { UseFormGetValues } from 'react-hook-form'

import { Screen } from 'constants/tracking/screens'
import { PaymentsProvider } from 'constants/payments'
import { CreditCardType } from 'constants/credit-card'
import useTracking from 'hooks/useTracking/useTracking'
import { clickEvent, tokenizationOutcomeEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { extraServiceOrderTypeToTrackingTypeMap } from 'constants/extra-service'

import { getCardBrandIdentificator } from '../utils'
import { FormModel, CreditCardAddTrackingDetails } from '../types'

type Params = {
  creditCardType?: CreditCardType
  getFormValues: UseFormGetValues<FormModel>
  trackingTargetDetails: CreditCardAddTrackingDetails
}

type TrackFromSubmitParams = {
  cardRegistrationId?: string | null
  hasFrontEndValidationPassed: boolean
}

type TrackTokenizationOutcomeParams = {
  errorMessage?: string
  isSuccess: boolean
  providerName: PaymentsProvider
}

export const useCreditCardFormTracking = ({
  getFormValues,
  creditCardType,
  trackingTargetDetails,
}: Params) => {
  const { track } = useTracking()

  const trackSubmit = useCallback(
    ({ cardRegistrationId, hasFrontEndValidationPassed }: TrackFromSubmitParams) => {
      const cardBrandIdentificator = hasFrontEndValidationPassed
        ? null
        : getCardBrandIdentificator(getFormValues().cardNumber)

      const cardSubmissionDetails = {
        valid: hasFrontEndValidationPassed,
        card_registration_id: cardRegistrationId ?? null,
        card_details_stored: !getFormValues().isSingleUse,
        card_brand: creditCardType || CreditCardType.Unknown,
        ...(cardBrandIdentificator && {
          card_brand_identificator: cardBrandIdentificator,
        }),
      }

      const trackingOrderType =
        extraServiceOrderTypeToTrackingTypeMap[trackingTargetDetails.type] ||
        trackingTargetDetails.type

      track(
        clickEvent({
          target: ClickableElement.UseThisCard,
          screen: Screen.CreditCardAdd,
          targetDetails: JSON.stringify({
            ...trackingTargetDetails,
            type: trackingOrderType,
            ...cardSubmissionDetails,
          }),
        }),
      )
    },
    [track, getFormValues, creditCardType, trackingTargetDetails],
  )

  const trackTokenizationOutcome = useCallback(
    ({ isSuccess, errorMessage, providerName }: TrackTokenizationOutcomeParams) => {
      track(
        tokenizationOutcomeEvent({
          providerName,
          errorMessage,
          checkoutType: trackingTargetDetails.type,
          outcome: isSuccess ? 'success' : 'failure',
          ...('transaction_id' in trackingTargetDetails && {
            transactionId: trackingTargetDetails.transaction_id,
          }),
          ...('service_order_id' in trackingTargetDetails && {
            orderId: trackingTargetDetails.service_order_id,
          }),
        }),
      )
    },
    [track, trackingTargetDetails],
  )

  return { trackSubmit, trackTokenizationOutcome }
}
