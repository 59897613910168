'use client'

import { ReactNode, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Text, Card, Cell, Button, Spacer, Icon } from '@vinted/web-ui'
import { compact, noop } from 'lodash'
import { Discover32, Mastercard32, Visa32 } from '@vinted/payment-icons'
import { Lock16 } from '@vinted/monochrome-icons'
import uuid from 'uuid'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import ContentLoader from 'components/ContentLoader'
import SeparatedList from 'components/SeparatedList'
import ScrollableArea from 'components/ScrollableArea'

import * as api from 'data/api'
import { transformCreditCardDto } from 'data/transformers/credit-card'
import { transformPaymentAuthAction } from 'data/transformers/payment'
import { transformCreditCardRegistrationDto } from 'data/transformers/credit-card-registration'
import { transformCreditCardRegistrationBrandDto } from 'data/transformers/credit-card-registration-brands'

import { getUserRealName } from 'state/session/selectors'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import {
  PaymentAuthActionModel,
  CreditCardAddSourceModel,
  CreditCardRegistrationModel,
  CreditCardConfigurationModel,
  CreditCardBrandModel,
} from 'types/models'
import { CreditCardModel } from 'types/models/credit-card'

import { redirectToPage } from 'libs/utils/window'
import { getBrowserAttributes } from 'libs/utils/threeDS2'
import { getLocalStorageItem, setLocalStorageItem } from 'libs/utils/localStorage'

import {
  clickEvent,
  viewScreenEvent,
  buyerViewCheckoutEvent,
  checkoutInteractionEvent,
  CheckoutInteractionType,
  viewCreditCardAddEvent,
} from 'libs/common/event-tracker/events'

import { UiState } from 'constants/ui'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import {
  CreditCardRequiredField,
  CreditCardAddSourceEntityType,
  CreditCardType,
} from 'constants/credit-card'

import {
  CardCVC,
  CardExpirationDate,
  CardName,
  CardNumber,
  CardPostalCode,
  CardSingleUse,
} from './fields'
import { getCobrandedCardBrandIdentificator, transformFormToCard } from './utils'
import { useCreditCardFormTracking, useSingleCheckoutCreditCardFormTracking } from './hooks'
import { getTokenizer, tokenizeCard } from './tokenizers'
import { IS_CC_SAVE_CONFIRMATION_MODAL_SEEN_KEY } from './constants'
import { Field, FormModel, TrackableField, CreditCardAddTrackingDetails } from './types'

import CreditCardFormThreeDS2 from './CreditCardFormThreeDS2'
import CreditCardSaveConfirmationModal from './CreditCardSaveConfirmationModal'

const FIELD_TRACKING_NAMES = {
  [Field.Name]: 'full_name',
  [Field.CardNumber]: 'card_number',
  [Field.ExpirationDate]: 'expiration_date',
  [Field.CVC]: 'security_code',
  [Field.PostalCode]: 'postal_Code',
}

const brandCountRequiredForCobrandedStatus = 2

function isSaveCreditCardModalAlreadySeen() {
  return getLocalStorageItem(IS_CC_SAVE_CONFIRMATION_MODAL_SEEN_KEY) === 'true'
}

enum SaveCreditCardModalState {
  WasShownOnLastSubmit,
  WasShownBeforeLastSubmit,
  NotShown,
  Shown,
}

type ThreeDS2AuthState = {
  action?: PaymentAuthActionModel
  uiState: UiState
}

type Props = {
  isVisible?: boolean
  source: CreditCardAddSourceModel
  errorMessage?: string
  allowSingleUse?: boolean
  submitActionText?: ReactNode
  trackingTargetDetails: CreditCardAddTrackingDetails
  onLoaded?: () => void
  onCancel?: () => void
  onTrackableFieldChange?: (field: TrackableField) => void
  onSuccess: (card: CreditCardModel) => void
}

const CreditCardForm = ({
  source,
  isVisible,
  errorMessage,
  onCancel,
  onSuccess,
  submitActionText,
  trackingTargetDetails,
  allowSingleUse = false,
  onLoaded = noop,
  onTrackableFieldChange = noop,
}: Props) => {
  const translate = useTranslate('credit_card_add')
  const { track } = useTracking()
  const realUserName = useSelector(getUserRealName)
  const formMethods = useForm<FormModel>({
    mode: 'onTouched',
    defaultValues: { [Field.Name]: realUserName || '' },
  })
  const { handleSubmit, formState, getValues, setValue, trigger } = formMethods
  const { errors } = formState
  const [registrationConfig, setRegistrationConfig] = useState<CreditCardRegistrationModel>()
  const [matchedCardConfig, setMatchedCardConfig] = useState<CreditCardConfigurationModel>()
  const [userSelectedCardBrand, setUserSelectedCardBrand] = useState<CreditCardType | null>(null)
  const [prevCardNumber, setPrevCardNumber] = useState<string | null>(null)
  const [globalError, setGlobalError] = useState<string | null>(null)
  const [registrationId, setRegistrationId] = useState<string | null>(null)
  const [threeDS2Auth, setThreeDS2Auth] = useState<ThreeDS2AuthState>({ uiState: UiState.Idle })
  const [cardBrands, setCardBrands] = useState<Array<CreditCardBrandModel> | null>([])
  const [isCobranded, setIsCobranded] = useState(false)
  const [trackIsCobranded, setTrackIsCobranded] = useState(false)
  const [saveCreditCardModalState, setSaveCreditCardModalState] =
    useState<SaveCreditCardModalState>(
      isSaveCreditCardModalAlreadySeen()
        ? SaveCreditCardModalState.WasShownBeforeLastSubmit
        : SaveCreditCardModalState.NotShown,
    )

  const isFrontendCobrandedCardCheckFSEnabled = useSelector(
    getIsFeatureSwitchEnabled('frontend_cobranded_card_check'),
  )

  const isSingleCheckout = source.entityType === CreditCardAddSourceEntityType.SingleCheckout

  const useFormTracking =
    source.entityType === CreditCardAddSourceEntityType.SingleCheckout
      ? useSingleCheckoutCreditCardFormTracking
      : useCreditCardFormTracking

  const { trackSubmit, trackTokenizationOutcome } = useFormTracking({
    trackingTargetDetails,
    getFormValues: getValues,
    creditCardType: matchedCardConfig?.type,
  })

  useEffect(() => {
    if (errorMessage) setGlobalError(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    if (!globalError) return
    if (saveCreditCardModalState !== SaveCreditCardModalState.WasShownOnLastSubmit) return

    setValue(Field.IsSingleUse, true)
  }, [setValue, globalError, saveCreditCardModalState])

  useEffect(() => {
    const event =
      isSingleCheckout && source.entityId
        ? viewCreditCardAddEvent({
            checkoutId: source.entityId.toString(),
          })
        : viewScreenEvent({
            screen: Screen.CreditCardAdd,
          })

    track(event)
  }, [source.entityType, track, isSingleCheckout, source.entityId])

  useEffect(() => {
    if (!isVisible && trackIsCobranded) {
      track(
        checkoutInteractionEvent({
          screen: Screen.CreditCardAdd,
          action: CheckoutInteractionType.CoBrandedCard,
          ...('service_order_id' in trackingTargetDetails && {
            orderId: trackingTargetDetails.service_order_id,
          }),
          ...('transaction_id' in trackingTargetDetails && {
            transactionId: trackingTargetDetails.transaction_id,
          }),
          actionDetails: {
            co_branded_card: isCobranded,
          },
        }),
      )
    }
  }, [isVisible, isCobranded, cardBrands, trackingTargetDetails, trackIsCobranded, track])

  useEffect(() => {
    if (registrationConfig) onLoaded()
  }, [onLoaded, registrationConfig])

  useEffect(() => {
    async function getCreditCardConfiguration() {
      const response = await api.getCreditCardRegistration()

      if ('errors' in response) return

      const registration = transformCreditCardRegistrationDto(response.credit_card_registration)
      setRegistrationConfig(registration)

      getTokenizer(registration.provider).load(registration)
    }

    getCreditCardConfiguration()
  }, [])

  async function trackFieldValidity(field: Field) {
    await trigger(field)

    onTrackableFieldChange({
      name: FIELD_TRACKING_NAMES[field],
      isValid: !errors[field],
    })
  }

  function handleFieldBlur(field: Field) {
    return () => trackFieldValidity(field)
  }

  function clearCardBrands() {
    if (!isCobranded) return

    setCardBrands([])
    setIsCobranded(false)
    setTrackIsCobranded(false)
    setUserSelectedCardBrand(null)
  }

  async function handleCobrandedCardRequest(cardBIN: string | null) {
    const isValidCardBINChanged =
      cardBIN &&
      !errors[Field.CardNumber] &&
      (getValues().cardNumber !== prevCardNumber ||
        (getValues().cardNumber === prevCardNumber && !isCobranded))

    if (isValidCardBINChanged) {
      const response = await api.getCreditCardRegistrationBrands(cardBIN)

      if ('brands' in response) {
        const brands = transformCreditCardRegistrationBrandDto(response.brands)

        setCardBrands(brands.cardBrands)
        setIsCobranded(brands.cardBrands?.length === brandCountRequiredForCobrandedStatus)
        setUserSelectedCardBrand(brands.preselectedBrand)
        setTrackIsCobranded(true)
      }
    }

    setPrevCardNumber(getValues().cardNumber)
  }

  async function handleCardNumberBlur() {
    await trackFieldValidity(Field.CardNumber)

    if (isFrontendCobrandedCardCheckFSEnabled) {
      const cardBrandIdentificatior = getCobrandedCardBrandIdentificator(getValues().cardNumber)
      await handleCobrandedCardRequest(cardBrandIdentificatior)
    }
  }

  function handleCvcInfoIconClick() {
    track(
      clickEvent({
        screen: Screen.CreditCardAdd,
        target: ClickableElement.CvvFieldInfo,
        targetDetails: JSON.stringify(trackingTargetDetails),
      }),
    )
  }

  function handleInvalidFormSubmit() {
    trackSubmit({ hasFrontEndValidationPassed: false, clickId: uuid.v4() })
  }

  async function submitForm(values: FormModel) {
    setGlobalError(null)

    const clickId = uuid.v4()

    if (saveCreditCardModalState === SaveCreditCardModalState.WasShownOnLastSubmit) {
      setSaveCreditCardModalState(SaveCreditCardModalState.WasShownBeforeLastSubmit)
    }

    if (saveCreditCardModalState === SaveCreditCardModalState.Shown) {
      setSaveCreditCardModalState(SaveCreditCardModalState.WasShownOnLastSubmit)
    }

    if (!matchedCardConfig || !registrationConfig) {
      setGlobalError(translate('card_number.errors.format'))
      trackSubmit({ hasFrontEndValidationPassed: false, clickId })

      return
    }

    const card = transformFormToCard(values, matchedCardConfig, userSelectedCardBrand)

    if (!card) {
      setGlobalError(translate('errors.generic'))
      trackSubmit({ hasFrontEndValidationPassed: false, clickId })

      return
    }

    const tokenizationResult = await tokenizeCard(registrationConfig.provider, card)

    if (!tokenizationResult.encryptedCard || !tokenizationResult.id) {
      setGlobalError(tokenizationResult.error || translate('errors.generic'))
      trackTokenizationOutcome({
        isSuccess: false,
        errorMessage: tokenizationResult.error,
        providerName: registrationConfig.provider,
        clickId,
      })
      trackSubmit({
        hasFrontEndValidationPassed: true,
        cardRegistrationId: tokenizationResult.id,
        clickId,
      })

      return
    }

    trackTokenizationOutcome({
      isSuccess: true,
      providerName: registrationConfig.provider,
      clickId,
    })

    const registerResponse = await api.registerCreditCard({
      id: tokenizationResult.id,
      brand: card.brand,
      registrationData: tokenizationResult.encryptedCard,
      isSingleUse: getValues().isSingleUse,
      encryptedCardDetails: tokenizationResult.encryptedCardDetails,
      browserAttributes: getBrowserAttributes(),
      source,
      isCobranded,
    })

    if ('errors' in registerResponse) {
      const fieldErrors = compact(registerResponse.errors.map(({ value }) => value)).join('\n')

      setGlobalError(fieldErrors || registerResponse.message || translate('errors.generic'))
      trackSubmit({
        hasFrontEndValidationPassed: true,
        cardRegistrationId: tokenizationResult.id,
        clickId,
      })

      return
    }

    const { authentication_redirect_url, credit_card, authentication_action } = registerResponse

    if (authentication_redirect_url) {
      redirectToPage(authentication_redirect_url)

      return
    }

    if (authentication_action) {
      setThreeDS2Auth({
        uiState: UiState.Pending,
        action: transformPaymentAuthAction(authentication_action),
      })
      setRegistrationId(tokenizationResult.id)

      return
    }

    if (credit_card) {
      trackSubmit({
        hasFrontEndValidationPassed: true,
        cardRegistrationId: tokenizationResult.id,
        clickId,
      })
      onSuccess(transformCreditCardDto(credit_card))
    }
  }

  function trackSaveCreditCardModalVisibility() {
    if (!source.entityId) return

    if (isSingleCheckout) {
      track(
        viewCreditCardAddEvent({
          screen: Screen.SaveCreditCardReminder,
          checkoutId: source.entityId.toString(),
        }),
      )

      return
    }

    if (source.entityId)
      track(
        buyerViewCheckoutEvent({
          screen: Screen.SaveCreditCardReminder,
          transactionId: Number(source.entityId),
        }),
      )
  }

  function isSaveConfirmationModalEnabled(isSingleUse: boolean) {
    if (!isSingleUse) return false

    return saveCreditCardModalState === SaveCreditCardModalState.NotShown
  }

  async function interceptSubmit(values: FormModel) {
    if (!isSaveConfirmationModalEnabled(values.isSingleUse)) {
      await submitForm(values)

      return
    }

    if (source.entityType === CreditCardAddSourceEntityType.Transaction || isSingleCheckout) {
      trackSaveCreditCardModalVisibility()
    }

    setLocalStorageItem(IS_CC_SAVE_CONFIRMATION_MODAL_SEEN_KEY, 'true')
    setSaveCreditCardModalState(SaveCreditCardModalState.Shown)
  }

  const handleThreeDS2Failure = useCallback(
    (error?: string) => {
      setGlobalError(error || translate('errors.generic'))

      setThreeDS2Auth({
        uiState: UiState.Failure,
      })
    },
    [translate],
  )

  function renderThreeDS2() {
    return (
      <CreditCardFormThreeDS2
        registrationId={registrationId}
        initialAuthAction={threeDS2Auth.action}
        onSuccess={onSuccess}
        onFailure={handleThreeDS2Failure}
      />
    )
  }

  async function handleSaveCreditCardConfirm() {
    setValue(Field.IsSingleUse, false)

    await submitForm(getValues())
  }

  async function handleSaveCreditCardCancel() {
    await submitForm(getValues())
  }

  function renderCardSaveConfirmationModal() {
    return (
      <CreditCardSaveConfirmationModal
        show={saveCreditCardModalState === SaveCreditCardModalState.Shown}
        entityId={source.entityId}
        isSingleCheckout={isSingleCheckout}
        onConfirm={handleSubmit(handleSaveCreditCardConfirm, handleInvalidFormSubmit)}
        onCancel={handleSubmit(handleSaveCreditCardCancel, handleInvalidFormSubmit)}
      />
    )
  }

  function renderCancelButton() {
    if (!onCancel) return null

    return (
      <Button styling={Button.Styling.Flat} text={translate('actions.cancel')} onClick={onCancel} />
    )
  }

  function renderSaveButton() {
    const isSubmitting = formState.isSubmitting || threeDS2Auth.uiState === UiState.Pending

    return (
      <Button
        disabled={isSubmitting}
        isLoading={isSubmitting}
        type={Button.Type.Submit}
        styling={Button.Styling.Filled}
        text={submitActionText || translate('actions.submit')}
        testId="credit-card-form--submit-button"
      />
    )
  }

  function renderActions() {
    return (
      <Cell>
        {renderSaveButton()}
        <Spacer size={Spacer.Size.Small} />
        {renderCancelButton()}
      </Cell>
    )
  }

  function renderTitle() {
    return (
      <>
        <SeparatedList separator={<Spacer />}>
          <Text text={translate('header.title')} type={Text.Type.Heading} />
          <div className="u-flexbox u-flex-direction-row u-align-items-center">
            <Text text={translate('header.subtitle')} theme="muted" />
            <Spacer orientation={Spacer.Orientation.Vertical} />
            <Icon name={Lock16} color={Icon.Color.GreyscaleLevel3} testId="cc-form-lock-icon" />
          </div>
          <SeparatedList separator={<Spacer orientation={Spacer.Orientation.Vertical} />}>
            <Icon name={Mastercard32} testId="cc-form-master-icon" />
            <Icon name={Visa32} testId="cc-form-visa-icon" />
            <Icon name={Discover32} testId="cc-form-discover-icon" />
          </SeparatedList>
        </SeparatedList>
        <Spacer />
      </>
    )
  }

  function renderFields() {
    if (!registrationConfig) return null

    const showPostalCode = registrationConfig.requiredFields.includes(
      CreditCardRequiredField.PostalCode,
    )

    return (
      <SeparatedList separator={<Spacer size={Spacer.Size.XLarge} />}>
        <Card>
          <Cell>
            <SeparatedList separator={<Spacer size={Spacer.Size.X2Large} />}>
              <CardName shouldFocus={!realUserName} onBlur={handleFieldBlur(Field.Name)} />
              <CardNumber
                shouldFocus={!!realUserName}
                cardConfigs={registrationConfig.configuration}
                onConfigMatched={setMatchedCardConfig}
                onBlur={handleCardNumberBlur}
                isCobranded={isCobranded}
                cardBrands={cardBrands}
                userSelectedCardBrand={userSelectedCardBrand}
                transactionId={Number(source.entityId)}
                orderId={source.orderId}
                onSelectedCardBrand={setUserSelectedCardBrand}
                onClearCardBrands={clearCardBrands}
              />
              <div className="u-flexbox u-flex-direction-row">
                <div className="credit-card-form__expiry">
                  <CardExpirationDate onBlur={handleFieldBlur(Field.ExpirationDate)} />
                </div>
                <Spacer size={Spacer.Size.X2Large} orientation={Spacer.Orientation.Vertical} />
                <div className="credit-card-form__cvc">
                  <CardCVC
                    config={matchedCardConfig}
                    onInfoIconClick={handleCvcInfoIconClick}
                    onBlur={handleFieldBlur(Field.CVC)}
                  />
                </div>
              </div>
              {showPostalCode && <CardPostalCode onBlur={handleFieldBlur(Field.PostalCode)} />}
            </SeparatedList>
          </Cell>
        </Card>
        {allowSingleUse && <CardSingleUse />}
      </SeparatedList>
    )
  }

  function renderGlobalError() {
    if (!globalError) return null

    return (
      <>
        <Cell theme="warning" styling={Cell.Styling.Wide} body={globalError} />
        <Spacer size={Spacer.Size.XLarge} />
      </>
    )
  }

  function renderForm() {
    return (
      <Cell>
        {renderTitle()}
        {renderGlobalError()}
        {renderFields()}
      </Cell>
    )
  }

  if (!registrationConfig) {
    return <ContentLoader styling={ContentLoader.Styling.Wide} testId="credit-card-form--loader" />
  }

  return (
    <>
      <ScrollableArea>
        <div className="u-position-relative">
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(interceptSubmit, handleInvalidFormSubmit)}>
              {renderForm()}
              {renderCardSaveConfirmationModal()}
            </form>
          </FormProvider>
        </div>
      </ScrollableArea>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(interceptSubmit, handleInvalidFormSubmit)}>
          {renderActions()}
        </form>
      </FormProvider>
      {renderThreeDS2()}
    </>
  )
}

export default CreditCardForm
