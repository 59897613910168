import { some, isEmpty } from 'lodash'

import { loadScript } from 'libs/utils/script'
import { CreditCardRegistrationModel, CreditCardRegistrationPrepareModel } from 'types/models'

import { CreditCard } from '../types'
import { TokenizeStatus, TokenizeResult } from './types'

const RESOURCES = {
  test: 'https://test.adyen.com/hpp/cse/js/8215282108561142.shtml',
  live: 'https://live.adyen.com/hpp/cse/js/2615399527220859.shtml',
}

function load(registration: CreditCardRegistrationModel) {
  loadScript({ id: 'adyen-tokenizer', src: RESOURCES[registration.site || 'live'] })
}

async function tokenize(
  card: CreditCard,
  registrationPrepare: CreditCardRegistrationPrepareModel,
): Promise<TokenizeResult> {
  if (!window.adyen) return Promise.resolve({ status: TokenizeStatus.LibraryNotLoaded })

  const cseInstance = window.adyen.createEncryption(registrationPrepare.accessKey)
  const generationtime = registrationPrepare.preregistrationData
  const expiryMonth = card.expirationMonth.toString()
  const expiryYear = card.expirationYear.toString()

  const cardData = {
    number: card.cardNumber,
    cvc: card.cvc,
    holderName: card.name,
    expiryMonth,
    expiryYear,
    generationtime,
  }

  const encryptedCard = cseInstance.encrypt(cardData)
  const encryptedCardDetails = {
    cardNumber: cseInstance.encrypt({ number: card.cardNumber, generationtime }),
    expirationMonth: cseInstance.encrypt({ expiryMonth, generationtime }),
    expirationYear: cseInstance.encrypt({ expiryYear, generationtime }),
    cvc: cseInstance.encrypt({ cvc: card.cvc, generationtime }),
  }
  const allFieldsEncrypted = !some(encryptedCardDetails, isEmpty)

  return Promise.resolve({
    status: encryptedCard ? TokenizeStatus.Ok : TokenizeStatus.ProviderError,
    encryptedCard,
    encryptedCardDetails: allFieldsEncrypted ? (encryptedCardDetails as any) : undefined,
  })
}

export default {
  load,
  tokenize,
}
