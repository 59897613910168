'use client'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { noop } from 'lodash'

import { getLocale } from 'state/intl/selectors'
import {
  AdyenCheckout,
  AdyenCheckoutSite,
  AdyenCheckoutError,
  AdyenAuthState,
  AdyenAuthAction,
  AdyenAuthActionType,
  AdyenCheckoutConfig,
} from 'types/adyen'
import { PaymentAuthActionModel, ThreeDS2ConfigurationModel } from 'types/models'
import { UiState } from 'constants/ui'
import useTranslate from 'hooks/useTranslate'

import loadAdyenResources from './loadAdyenResources'
import AdyenBlikAwait from './AdyenBlikAwait'
import AdyenAwait from './AdyenAwait'

type Props = {
  authAction: PaymentAuthActionModel | null
  threeDS2Config: ThreeDS2ConfigurationModel | null
  onError: (message: string) => void
  onContinue: (state: AdyenAuthState) => void
  onInputRequested?: () => void
}

const ADYEN_CHALLENGE_WINDOW_SIZE = '05'

function isStateFinal(ui: UiState) {
  return ui === UiState.Failure || ui === UiState.Success
}

function isBlikAwaitAction(action: AdyenAuthAction) {
  return action.paymentMethodType === 'blik' && action.type === AdyenAuthActionType.Await
}

function isAwaitAction(action?: AdyenAuthAction) {
  return action?.type === AdyenAuthActionType.ThreeDS2 || action?.type === AdyenAuthActionType.Await
}

const Adyen = ({
  onError,
  onContinue,
  authAction,
  threeDS2Config,
  onInputRequested = noop,
}: Props) => {
  const [adyenCheckout, setAdyenCheckout] = useState<AdyenCheckout>()
  const [resourceLoadingState, setResourceLoadingState] = useState<UiState>(UiState.Idle)

  const adyenAction = authAction?.adyen || authAction?.adyenBank
  const translate = useTranslate('threeds2')
  const locale = useSelector(getLocale)

  function handleOnAdditionalDetails(state: AdyenAuthState) {
    onContinue(state)
  }

  function handleOnError(error: AdyenCheckoutError) {
    onError(error.message)
  }

  async function initAdyenWebCheckout() {
    if (!threeDS2Config || !window.AdyenCheckout) {
      onError(translate('errors.resources_failed_loading'))

      return
    }

    const { providerConfig } = threeDS2Config

    const adyenCheckoutConfig: AdyenCheckoutConfig = {
      locale,
      environment: providerConfig.site,
      clientKey: providerConfig.apiKey,
      paymentMethodsConfiguration: {
        threeDS2: {
          challengeWindowSize: ADYEN_CHALLENGE_WINDOW_SIZE,
        },
      },
      onAdditionalDetails: handleOnAdditionalDetails,
      onError: handleOnError,
    }

    const checkout = await window.AdyenCheckout(adyenCheckoutConfig)
    setAdyenCheckout(checkout)
  }

  useEffect(() => {
    if (!isStateFinal(resourceLoadingState)) return

    initAdyenWebCheckout()
  }, [adyenAction, resourceLoadingState]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!threeDS2Config || !adyenAction) return
    if (resourceLoadingState !== UiState.Idle) return

    async function loadResources(site: AdyenCheckoutSite) {
      setResourceLoadingState(UiState.Pending)

      try {
        await loadAdyenResources(site)
        setResourceLoadingState(UiState.Success)
      } catch {
        setResourceLoadingState(UiState.Failure)
      }
    }

    loadResources(threeDS2Config.providerConfig.site)
  }, [resourceLoadingState, threeDS2Config, adyenAction])

  useEffect(() => {
    if (!isAwaitAction(adyenAction)) return

    onInputRequested()
  }, [adyenAction, onInputRequested])

  if (!adyenCheckout || !adyenAction) return null

  if (isBlikAwaitAction(adyenAction)) {
    return <AdyenBlikAwait adyenAction={adyenAction} adyenCheckout={adyenCheckout} />
  }

  if (isAwaitAction(adyenAction)) {
    return <AdyenAwait adyenAction={adyenAction} adyenCheckout={adyenCheckout} />
  }

  throw new Error(`Unknown Adyen action ${adyenAction.type}`)
}

export default Adyen
