'use client'

import { ChangeEvent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

import { Field, FormModel } from '../types'

const DEFAULT_IS_SINGLE_USE_CHECKBOX_VALUE = true

const CardSingleUse = () => {
  const { setValue, control } = useFormContext<FormModel>()
  const translate = useTranslate('credit_card_add.single_use')

  function handleOnChange({ target }: ChangeEvent<HTMLInputElement>) {
    setValue(Field.IsSingleUse, !target.checked)
  }

  return (
    <Controller
      defaultValue={DEFAULT_IS_SINGLE_USE_CHECKBOX_VALUE}
      render={({ field: { name, value: isSingleUse } }) => (
        <Checkbox
          name={name}
          text={translate('consent')}
          checked={!isSingleUse}
          testId="credit-card-form-single-use-checkbox"
          onChange={handleOnChange}
        />
      )}
      name={Field.IsSingleUse}
      control={control}
    />
  )
}

export default CardSingleUse
