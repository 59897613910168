'use client'

import { ReactNode } from 'react'
import { Cell, Button, Modal, Navigation } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import ScrollableArea from 'components/ScrollableArea'
import { CreditCardAddSourceModel, CreditCardModel } from 'types/models'

import CreditCardForm from './CreditCardForm'
import { TrackableField, CreditCardAddTrackingDetails } from './types'

type Props = {
  show: boolean
  source: CreditCardAddSourceModel
  errorMessage?: string
  allowSingleUse?: boolean
  submitActionText?: ReactNode
  trackingTargetDetails: CreditCardAddTrackingDetails
  onTrackableFieldChange?: (field: TrackableField) => void
  onCreditCardAddSuccess: (card: CreditCardModel) => void
  onBack: () => void
  onClose?: () => void
}

const CreditCardFormModal = ({
  source,
  show,
  allowSingleUse,
  submitActionText,
  trackingTargetDetails,
  errorMessage,
  onCreditCardAddSuccess,
  onBack,
  onClose,
  onTrackableFieldChange,
}: Props) => {
  const translate = useTranslate('credit_card_add.modal')

  function handleSuccess(card: CreditCardModel) {
    onCreditCardAddSuccess(card)
  }

  function renderNavigation() {
    return (
      <Navigation
        body={translate('title')}
        right={
          onClose && (
            <Button
              inline
              iconName={X24}
              styling={Button.Styling.Flat}
              onClick={onClose}
              testId="close-credit-card-form-modal"
            />
          )
        }
      />
    )
  }

  function renderCreditCardForm() {
    return (
      <ScrollableArea>
        <Cell styling={Cell.Styling.Tight}>
          <CreditCardForm
            isVisible={show}
            errorMessage={errorMessage}
            source={source}
            allowSingleUse={allowSingleUse}
            submitActionText={submitActionText}
            onSuccess={handleSuccess}
            onCancel={onBack}
            onTrackableFieldChange={onTrackableFieldChange}
            trackingTargetDetails={trackingTargetDetails}
          />
        </Cell>
      </ScrollableArea>
    )
  }

  return (
    <Modal show={show} hasScrollableContent testId="credit-card-form-modal">
      <div className="u-ui-padding-regular u-fill-width">
        {renderNavigation()}
        {renderCreditCardForm()}
      </div>
    </Modal>
  )
}

export default CreditCardFormModal
