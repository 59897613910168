'use client'

import { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import PaymentAuth from 'components/PaymentAuth'

import useTranslate from 'hooks/useTranslate'

import {
  CreditCardModel,
  PaymentAuthActionModel,
  ThreeDS2ConfigurationModel,
  PaymentAuthStateModel,
} from 'types/models'
import { authenticateCreditCard, getCheckoutConfiguration } from 'data/api'
import { transformCheckoutConfigurationDto } from 'data/transformers/checkout-configuration'
import { transformCreditCardDto } from 'data/transformers/credit-card'
import { transformPaymentAuthAction } from 'data/transformers/payment'
import { navigateToPage } from 'libs/utils/window'

type Props = {
  registrationId: string | null
  initialAuthAction?: PaymentAuthActionModel
  onSuccess: (card: CreditCardModel) => void
  onFailure: (error?: string) => void
}

const CreditCardFormThreeDS2 = ({
  registrationId,
  initialAuthAction,
  onSuccess,
  onFailure,
}: Props) => {
  const translate = useTranslate('credit_card_add.threeds2')
  const [isInputRequested, setIsInputRequested] = useState(false)
  const [authAction, setAuthAction] = useState<PaymentAuthActionModel | null>(null)
  const [threeDS2Config, setThreeDS2Config] = useState<ThreeDS2ConfigurationModel | null>(null)

  const handleOnError = useCallback(
    (message?: string) => {
      onFailure(message || translate('errors.resources_failed_loading'))
    },
    [translate, onFailure],
  )

  useEffect(() => {
    async function fetchConfiguration() {
      const response = await getCheckoutConfiguration()

      if ('errors' in response) {
        handleOnError(response.errors[0]?.value || response.message)

        return
      }

      const config = transformCheckoutConfigurationDto(response.checkout_configuration)

      setThreeDS2Config(config.threeDS2)
    }

    fetchConfiguration()
  }, [handleOnError])

  useEffect(() => {
    if (initialAuthAction) {
      setAuthAction(initialAuthAction)
    }
  }, [initialAuthAction])

  async function submitAuthResult(state: PaymentAuthStateModel) {
    if (!registrationId) {
      handleOnError()

      return
    }

    const response = await authenticateCreditCard({
      registrationId,
      paymentData: {
        details: state.data.details,
        data: state.data.paymentData,
      },
    })

    if ('errors' in response) {
      handleOnError(response.errors[0]?.value || response.message)

      return
    }

    const { authentication_action, authentication_redirect_url, credit_card } = response

    if (authentication_action) {
      setAuthAction(transformPaymentAuthAction(authentication_action))

      return
    }

    if (authentication_redirect_url) {
      navigateToPage(authentication_redirect_url)

      return
    }

    if (credit_card) {
      onSuccess(transformCreditCardDto(credit_card))
    }

    handleOnError()
  }

  function handleOnContinue(state: PaymentAuthStateModel) {
    setAuthAction(null)
    setIsInputRequested(false)
    submitAuthResult(state)
  }

  function handleInputRequested() {
    setIsInputRequested(true)
  }

  const containerClass = classNames({
    'threeds2__card-form-challenge': isInputRequested,
  })

  return (
    <div className={containerClass}>
      <PaymentAuth
        authAction={authAction}
        threeDS2Config={threeDS2Config}
        onError={handleOnError}
        onContinue={handleOnContinue}
        onInputRequested={handleInputRequested}
      />
    </div>
  )
}

export default CreditCardFormThreeDS2
