import { Icon } from '@vinted/web-ui'
import {
  Amex48,
  Visa48,
  Mastercard48,
  DinnerClub48,
  CartesBancaires48,
  Jbc48,
  Payconiq48,
  CreditCard48,
  Discover48,
  Maestro48,
  Paypal48,
  Blik48,
  Sofort48,
  PaymentsWallet48,
  Ideal48,
  Trustly48,
  Bancontact48,
  Przelewy2448,
} from '@vinted/payment-icons'

export const MapCreditCardBrandToIcon: Partial<
  Record<string, ComponentProps<typeof Icon>['name']>
> = {
  american_express: Amex48,
  diners_club: DinnerClub48,
  jbc: Jbc48,
  payconiq: Payconiq48,
  bcmc: Bancontact48,
  discover: Discover48,
  maestro: Maestro48,
  paypal: Paypal48,
  visa: Visa48,
  blik: Blik48,
  mastercard: Mastercard48,
  sofort: Sofort48,
  tink: Visa48,
  ideal: Ideal48,
  trustly: Trustly48,
  cartes_bancaires: CartesBancaires48,
  p24: Przelewy2448,
  wallet: PaymentsWallet48,
  credit_card: CreditCard48,
  unknown: CreditCard48,
}
