import { loadScript } from 'libs/utils/script'
import { loadStylesheet } from 'libs/utils/stylesheet'
import {
  AdyenCheckoutSite,
  AdyenCheckoutResource,
  AdyenCheckoutResourceType,
  AdyenCheckoutResourceConfig,
} from 'types/adyen'

import { ADYEN_CHECKOUT_RESOURCE_CONFIG } from './constants'

function getUrl(site: AdyenCheckoutSite, version: string, type: AdyenCheckoutResourceType) {
  return `https://checkoutshopper-${site}.adyen.com/checkoutshopper/sdk/${version}/adyen.${type}`
}

function getResourceLoader(url: string, { type, integrity }: AdyenCheckoutResource) {
  const crossOrigin = 'anonymous'
  const id = `adyen-${type}`

  switch (type) {
    case 'js':
      return loadScript({ id, src: url, crossOrigin, integrity })
    case 'css':
      return loadStylesheet({ id, href: url, crossOrigin, integrity })
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unknown type to load ${type}`)
  }
}

function getResourceLoaders(site: AdyenCheckoutSite, config: AdyenCheckoutResourceConfig) {
  const resources = config.resources[site]

  return resources.map(resource => {
    const url = getUrl(site, config.version, resource.type)

    return getResourceLoader(url, resource)
  })
}

function getAdyenCheckoutConfig() {
  return ADYEN_CHECKOUT_RESOURCE_CONFIG
}

function loadAdyenResources(site: AdyenCheckoutSite) {
  const config = getAdyenCheckoutConfig()

  return Promise.all(getResourceLoaders(site, config))
}

export default loadAdyenResources
