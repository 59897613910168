import { Icon } from '@vinted/web-ui'
import { CreditCard48 } from '@vinted/payment-icons'

import { MapCreditCardBrandToIcon } from 'constants/pay-in-method-icons'

type Props = {
  payInMethodBrand: string
}

const PayInMethodIcon = ({ payInMethodBrand }: Props) => {
  const normalizedBrand = payInMethodBrand.toLowerCase().replace(/[\s-]+/g, '_')
  const iconName = MapCreditCardBrandToIcon[normalizedBrand]

  if (!iconName) {
    return (
      <Icon
        name={CreditCard48}
        aria={{
          'aria-hidden': 'true',
        }}
        testId="pay-in-method-icon-credit-card"
      />
    )
  }

  return (
    <Icon
      name={iconName}
      aria={{
        'aria-hidden': 'true',
      }}
      testId={`pay-in-method-icon-${normalizedBrand}`}
    />
  )
}

export default PayInMethodIcon
