import { CreditCardRegistrationDto } from 'types/dtos'
import { CreditCardRegistrationModel } from 'types/models'

import { transformCreditCardConfigurationDtos } from './credit-card-configuration'

export const transformCreditCardRegistrationDto = ({
  provider,
  site,
  required_fields,
  configuration,
}: CreditCardRegistrationDto): CreditCardRegistrationModel => ({
  provider,
  site,
  requiredFields: required_fields,
  configuration: transformCreditCardConfigurationDtos(configuration),
})
