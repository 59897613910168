import {
  PaymentAuthStateModel,
  PaymentAuthActionModel,
  ThreeDS2ConfigurationModel,
} from 'types/models'
import { ThreeDS2Provider } from 'constants/checkout-configuration'

import Adyen from './Adyen'

type Props = {
  threeDS2Config: ThreeDS2ConfigurationModel | null
  authAction: PaymentAuthActionModel | null
  onError: (message: string) => void
  onContinue: (state: PaymentAuthStateModel) => void
  onInputRequested?: () => void
}

const PaymentAuth = ({
  onError,
  onContinue,
  authAction,
  threeDS2Config,
  onInputRequested,
}: Props) => {
  function renderAdyen() {
    return (
      <Adyen
        authAction={authAction}
        threeDS2Config={threeDS2Config}
        onError={onError}
        onContinue={onContinue}
        onInputRequested={onInputRequested}
      />
    )
  }

  if (!threeDS2Config) return null

  switch (threeDS2Config.provider) {
    case ThreeDS2Provider.Adyen:
    case ThreeDS2Provider.AdyenBank:
      return renderAdyen()
    default:
      return null
  }
}

export default PaymentAuth
