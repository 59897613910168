import { AdyenCheckoutResourceConfig } from 'types/adyen'

export const ADYEN_CHECKOUT_RESOURCE_CONFIG: AdyenCheckoutResourceConfig = {
  version: '5.2.0',
  resources: {
    test: [
      {
        type: 'js',
        integrity: 'sha384-qt4kgNMZ0UwOQ6AwZY31nwn5Cf4UU8wPfkhFu/tiF/Jta16AjBtfrkh1dFw2G7oP',
      },
      {
        type: 'css',
        integrity: 'sha384-gaEp/efBcplH05NvdYnrdMcaKBrhlK/jau5tB/MkhdOsY7sz6mUnu5fa26lNzt+i',
      },
    ],
    live: [
      {
        type: 'js',
        integrity: 'sha384-qt4kgNMZ0UwOQ6AwZY31nwn5Cf4UU8wPfkhFu/tiF/Jta16AjBtfrkh1dFw2G7oP',
      },
      {
        type: 'css',
        integrity: 'sha384-gaEp/efBcplH05NvdYnrdMcaKBrhlK/jau5tB/MkhdOsY7sz6mUnu5fa26lNzt+i',
      },
    ],
  },
}
