'use client'

import type { Thing } from 'schema-dts'

import { ReactNode } from 'react'
import Head from 'next/head'

import useTranslate from 'hooks/useTranslate'

type Props = {
  title: string
  titleSuffix?: string
  jsonLd?: Thing
  children?: ReactNode
  isIndexed?: boolean
  meta?: {
    robots?: 'noindex' | 'noindex, follow' | undefined
    description?: string
    og?: {
      url?: string
      type?: string
      title?: string
      brand?: string
      image?: string
      productId?: string
      description?: string
      priceAmount?: string
      priceCurrency?: string
    }
  }
}

const PageHead = ({
  title,
  titleSuffix = ' | Vinted',
  children,
  jsonLd,
  isIndexed = false,
  meta,
}: Props) => {
  const translate = useTranslate('main')
  const fullTitle = `${title}${titleSuffix}`
  const robotContent = meta?.robots ? meta.robots : 'noindex'

  const renderOpenGraphMetaTags = () => {
    const ogData = meta?.og

    if (!ogData) return null

    return (
      <>
        {ogData.url && <meta property="og:url" content={ogData.url} />}
        {ogData.type && <meta property="og:type" content={ogData.type} />}
        {ogData.title && <meta property="og:title" content={ogData.title} />}
        {ogData.brand && <meta property="og:brand" content={ogData.brand} />}
        {ogData.image && <meta property="og:image" content={ogData.image} />}
        {ogData.productId && <meta property="og:productId" content={ogData.productId} />}
        {ogData.description && <meta property="og:description" content={ogData.description} />}
        {ogData.priceAmount && <meta property="og:price:amount" content={ogData.priceAmount} />}
        {ogData.priceCurrency && (
          <meta property="og:price:currency" content={ogData.priceCurrency} />
        )}
      </>
    )
  }

  return (
    <Head>
      <title>{fullTitle}</title>
      <meta name="description" content={meta?.description ?? translate('page_description')} />
      {!isIndexed && <meta name="robots" content={robotContent} />}
      {meta?.robots && <meta name="robots" content={robotContent} />}
      {renderOpenGraphMetaTags()}

      {jsonLd && (
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(Object.assign(jsonLd, { '@context': 'https://schema.org' })),
          }}
        />
      )}

      {children}
    </Head>
  )
}

export default PageHead
