'use client'

import { FocusEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { InputText } from '@vinted/web-ui'

import { renderValidation } from 'components/Input'
import useTranslate from 'hooks/useTranslate'
import useFormValidationMessage from 'hooks/useFormValidationMessage'
import { numbersOnly } from 'libs/utils/number'

import { FormModel, Field } from '../types'

type Props = {
  onBlur: (event: FocusEvent<HTMLInputElement>) => void
}

const POSTAL_CODE_LENGTH = 5

const CardPostalCode = ({ onBlur }: Props) => {
  const translate = useTranslate('credit_card_add.postal_code')
  const {
    register,
    formState: { errors },
  } = useFormContext<FormModel>()
  const validationMessage = useFormValidationMessage(errors, 'credit_card_add')

  function validate(value: string) {
    const numeric = numbersOnly(value)

    if (numeric.length !== POSTAL_CODE_LENGTH) {
      return translate('errors.invalid')
    }

    return undefined
  }

  return (
    <InputText
      {...register(Field.PostalCode, {
        required: true,
        validate,
      })}
      title={translate('title')}
      placeholder={translate('placeholder')}
      uncontrolled
      styling={InputText.Styling.Tight}
      validation={renderValidation(validationMessage(Field.Name))}
      onBlur={onBlur}
    />
  )
}

export default CardPostalCode
