'use client'

import { useState, useEffect, ChangeEvent, FocusEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { InputText, Spacer } from '@vinted/web-ui'

import { renderValidation } from 'components/Input'
import useTranslate from 'hooks/useTranslate'
import useFormValidationMessage from 'hooks/useFormValidationMessage'
import { CreditCardBrandModel, CreditCardConfigurationModel } from 'types/models'
import { numbersOnly } from 'libs/utils/number'
import { CreditCardType } from 'constants/credit-card'

import { FormModel, Field } from '../types'
import { getConfigFromNumber, getDefaultConfig, isLuhnValid } from '../utils'

import CobrandedCardDropdown from './CobrandedCardDropdown'

type Props = {
  shouldFocus: boolean
  cardConfigs: Array<CreditCardConfigurationModel>
  onConfigMatched: (config: CreditCardConfigurationModel | undefined) => void
  onBlur: (event: FocusEvent<HTMLInputElement>) => void
  isCobranded: boolean
  cardBrands: Array<CreditCardBrandModel> | null
  userSelectedCardBrand: CreditCardType | null
  transactionId?: number | null
  orderId?: number
  onSelectedCardBrand: (creditCardType: CreditCardType | null) => void
  onClearCardBrands: () => void
}

const CardNumber = ({
  shouldFocus,
  cardConfigs,
  onConfigMatched,
  onBlur,
  isCobranded,
  cardBrands,
  userSelectedCardBrand,
  transactionId,
  orderId,
  onSelectedCardBrand,
  onClearCardBrands,
}: Props) => {
  const translate = useTranslate('credit_card_add')
  const [matchedCardConfig, setMatchedCardConfig] = useState(getDefaultConfig(cardConfigs))
  const {
    register,
    setValue,
    setFocus,
    watch,
    formState: { errors },
  } = useFormContext<FormModel>()
  const validationMessage = useFormValidationMessage(errors, 'credit_card_add')
  watch(Field.CardNumber, '')

  useEffect(() => {
    if (!shouldFocus) return

    setFocus(Field.CardNumber)
  }, [setFocus, shouldFocus])

  function validate(value: string) {
    const numeric = numbersOnly(value)

    if (!matchedCardConfig) return undefined

    const { cardNumberFormat, cardNumberLuhn, cardNumberMaxLength, cardNumberMinLength } =
      matchedCardConfig

    if (!new RegExp(cardNumberFormat).test(numeric)) {
      return translate('card_number.errors.format')
    }

    if (
      (cardNumberMaxLength && numeric.length > cardNumberMaxLength) ||
      (cardNumberMinLength && numeric.length < cardNumberMinLength)
    ) {
      return translate('card_number.errors.format')
    }

    if (cardNumberLuhn && !isLuhnValid(numeric)) {
      return translate('card_number.errors.luhn')
    }

    return undefined
  }

  function formatDisplayValue(cardNumber: string, config?: CreditCardConfigurationModel) {
    let formatted = numbersOnly(cardNumber)

    if (config) {
      formatted = formatted.substring(0, config.cardNumberMaxLength)
    }

    return formatted.match(/\d{1,4}/g)?.join(' ') ?? ''
  }

  function handleNumberChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target

    const numericValue = numbersOnly(value)
    const config = getConfigFromNumber(numericValue, cardConfigs)

    setMatchedCardConfig(config)
    onConfigMatched(config)

    setValue(Field.CardNumber, formatDisplayValue(numericValue, config))

    onClearCardBrands()
  }

  return (
    <div className="u-flexbox u-align-items-flex-start">
      <div className="u-fill-width">
        <InputText
          {...register(Field.CardNumber, {
            required: true,
            validate,
          })}
          title={translate('card_number.title')}
          placeholder={translate('card_number.placeholder')}
          uncontrolled
          styling={InputText.Styling.Tight}
          onChange={handleNumberChange}
          onBlur={onBlur}
          validation={renderValidation(validationMessage(Field.CardNumber))}
        />
      </div>
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
      <CobrandedCardDropdown
        matchedCardConfig={matchedCardConfig}
        isCobranded={isCobranded}
        cardBrands={cardBrands}
        userSelectedCardBrand={userSelectedCardBrand}
        onSelectedCardBrand={onSelectedCardBrand}
        transactionId={transactionId}
        orderId={orderId}
      />
    </div>
  )
}

export default CardNumber
