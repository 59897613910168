'use client'

import { useEffect, useRef } from 'react'

import { AdyenCheckout, AdyenComponentInstance, AdyenAuthAction } from 'types/adyen'

type Props = {
  adyenCheckout: AdyenCheckout
  adyenAction: AdyenAuthAction
}

const AdyenAwait = ({ adyenCheckout, adyenAction }: Props) => {
  const targetElement = useRef<HTMLDivElement>(null)
  const challengeComponent = useRef<AdyenComponentInstance>()

  useEffect(() => {
    const mountAdyenComponent = () => {
      if (!targetElement.current) return

      const component = adyenCheckout.createFromAction(adyenAction)
      const mounted = component.mount(targetElement.current)

      challengeComponent.current = mounted
    }

    mountAdyenComponent()

    return () => challengeComponent.current?.remove()
  }, [adyenAction, adyenCheckout, targetElement])

  return <div className="adyen-await" ref={targetElement} />
}

export default AdyenAwait
