import { PaymentsProvider } from 'constants/payments'
import { transformCreditCardRegistrationPrepareDto } from 'data/transformers/credit-card-registration-prepare'
import * as api from 'data/api'

import getTokenizer from './get-tokenizer'
import { CreditCard } from '../types'

async function tokenizeCard(provider: PaymentsProvider, card: CreditCard) {
  const result = await api.prepareCreditCardRegistration({
    name: card.name,
    cardBrand: card.brand,
  })

  if ('errors' in result) return { error: result.message }

  const registration = transformCreditCardRegistrationPrepareDto(result.info)
  const { encryptedCard, encryptedCardDetails, error } = await getTokenizer(provider).tokenize(
    card,
    registration,
  )

  if (!encryptedCard) return { error, id: registration.id }

  return { id: registration.id, encryptedCard, encryptedCardDetails }
}

export default tokenizeCard
