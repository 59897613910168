import { DisplayedCardBrandName } from 'constants/credit-card'

export const getDisplayedCardBrandName = (brand: string) => {
  const brandNameQuery = brand.toLowerCase().replace(/\s+|_/g, '')

  const brandType = Object.keys(DisplayedCardBrandName).find(
    brandName => brandName.toLowerCase() === brandNameQuery,
  )

  return brandType ? DisplayedCardBrandName[brandType] : brand
}
