import { CreditCardRegistrationPrepareDto } from 'types/dtos'
import { CreditCardRegistrationPrepareModel } from 'types/models'

import { transformCreditCardDto } from './credit-card'

export const transformCreditCardRegistrationPrepareDto = ({
  id,
  preregistration_data,
  access_key,
  card_registration_url,
  credit_card,
  external_code,
  provider,
  initialization_data,
}: CreditCardRegistrationPrepareDto): CreditCardRegistrationPrepareModel => ({
  id,
  preregistrationData: preregistration_data,
  accessKey: access_key,
  cardRegistrationUrl: card_registration_url,
  creditCard: credit_card ? transformCreditCardDto(credit_card) : null,
  externalCode: external_code,
  provider,
  initializationData: initialization_data,
})

export const transformCreditCardRegistrationPrepareDtos = (
  dtos: Array<CreditCardRegistrationPrepareDto>,
) => dtos.map(transformCreditCardRegistrationPrepareDto)
