import axios from 'axios'

import { CreditCardRegistrationPrepareModel } from 'types/models'

import { CreditCard } from '../types'
import { TokenizeResult, TokenizeStatus } from './types'

function load() {
  // MangoPay does not require loading of resources
}

async function tokenize(
  card: CreditCard,
  registrationPrepare: CreditCardRegistrationPrepareModel,
): Promise<TokenizeResult> {
  if (!registrationPrepare.cardRegistrationUrl) {
    return Promise.resolve({ status: TokenizeStatus.LibraryNotLoaded })
  }

  const month = `0${card.expirationMonth}`.slice(-2)
  const year = `${card.expirationYear}`.slice(-2)

  const params = new URLSearchParams()
  params.append('data', registrationPrepare.preregistrationData)
  params.append('accessKeyRef', registrationPrepare.accessKey)
  params.append('cardNumber', card.cardNumber)
  params.append('cardExpirationDate', `${month}${year}`)
  params.append('cardCvx', card.cvc.toString())

  const res = await axios.post(registrationPrepare.cardRegistrationUrl, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  if (res.data?.indexOf('data=') === 0) {
    return Promise.resolve({ status: TokenizeStatus.Ok, encryptedCard: res.data })
  }

  return Promise.resolve({ status: TokenizeStatus.ProviderError, error: JSON.stringify(res) })
}

export default {
  load,
  tokenize,
}
