import { useCallback } from 'react'
import { UseFormGetValues } from 'react-hook-form'

import { Screen } from 'constants/tracking/screens'
import { PaymentsProvider } from 'constants/payments'
import { CreditCardFormLocation, CreditCardType } from 'constants/credit-card'
import useTracking from 'hooks/useTracking/useTracking'
import {
  creditCardAddClickEvent,
  singleCheckoutTokenizationOutcomeEvent,
} from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { getCardBrandIdentificator } from '../utils'
import { FormModel, CreditCardAddTrackingDetails } from '../types'

type Params = {
  creditCardType?: CreditCardType
  getFormValues: UseFormGetValues<FormModel>
  trackingTargetDetails: CreditCardAddTrackingDetails
}

type TrackFromSubmitParams = {
  cardRegistrationId?: string | null
  hasFrontEndValidationPassed: boolean
  clickId?: string
}

type TrackTokenizationOutcomeParams = {
  errorMessage?: string
  isSuccess: boolean
  providerName: PaymentsProvider
  clickId?: string
}

export const useSingleCheckoutCreditCardFormTracking = ({
  getFormValues,
  creditCardType,
  trackingTargetDetails,
}: Params) => {
  const { track } = useTracking()

  const trackSubmit = useCallback(
    ({ hasFrontEndValidationPassed, clickId }: TrackFromSubmitParams) => {
      const cardBrandIdentificator = hasFrontEndValidationPassed
        ? null
        : getCardBrandIdentificator(getFormValues().cardNumber)

      const cardSubmissionDetails = {
        valid: hasFrontEndValidationPassed,
        cardDetailsStored: !getFormValues().isSingleUse,
        cardBrand: creditCardType || CreditCardType.Unknown,
        cardBrandIdentificator,
      }

      // extra.front_end_error, extra.autofilled to add
      track(
        creditCardAddClickEvent({
          target: ClickableElement.UseThisCard,
          screen: Screen.CreditCardAdd,
          location: CreditCardFormLocation.Checkout,
          clickId,
          ...cardSubmissionDetails,
          ...('checkout_id' in trackingTargetDetails && {
            checkoutId: trackingTargetDetails.checkout_id,
          }),
        }),
      )
    },
    [track, getFormValues, creditCardType, trackingTargetDetails],
  )

  const trackTokenizationOutcome = useCallback(
    ({ isSuccess, errorMessage, providerName, clickId }: TrackTokenizationOutcomeParams) => {
      track(
        singleCheckoutTokenizationOutcomeEvent({
          providerName,
          errorMessage,
          clickId,
          outcome: isSuccess ? 'success' : 'failure',
          ...('checkout_id' in trackingTargetDetails && {
            checkoutId: trackingTargetDetails.checkout_id,
          }),
        }),
      )
    },
    [track, trackingTargetDetails],
  )

  return { trackSubmit, trackTokenizationOutcome }
}
