import { CreditCardRegistrationBrandsDto } from 'types/dtos'
import { CreditCardRegistrationBrandsModel } from 'types/models'

import { transformCreditCardBrandDtos } from './credit-card-brand'

export const transformCreditCardRegistrationBrandDto = (
  brands: CreditCardRegistrationBrandsDto,
): CreditCardRegistrationBrandsModel => ({
  preselectedBrand: brands?.preselected_brand || null,
  cardBrands: transformCreditCardBrandDtos(brands?.card_brands) || null,
})
