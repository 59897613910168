import { CreditCardBrandDto } from 'types/dtos'
import { CreditCardBrandModel } from 'types/models'

export const transformCreditCardBrandDto = ({
  brand,
  title,
  icon_url,
}: CreditCardBrandDto): CreditCardBrandModel => ({
  brand,
  title,
  iconUrl: icon_url,
})

export const transformCreditCardBrandDtos = (dtos?: Array<CreditCardBrandDto> | null) =>
  dtos?.map(transformCreditCardBrandDto)
