'use client'

import { ChangeEvent, KeyboardEvent, FocusEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { InputText } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import useFormValidationMessage from 'hooks/useFormValidationMessage'
import { renderValidation } from 'components/Input'

import { numbersOnly } from 'libs/utils/number'

import { FormModel, Field } from '../types'
import { parseExpiryDate } from '../utils'

const SEPARATOR = '/'
const MIN_NUMBERS_LENGTH = 4
const MAX_NUMBERS_LENGTH = 6
const MAX_LENGTH = MAX_NUMBERS_LENGTH + SEPARATOR.length

function isExpired(expiry: Date) {
  const now = new Date()

  return expiry < now
}

type Props = {
  onBlur: (event: FocusEvent<HTMLInputElement>) => void
}

const CardExpirationDate = ({ onBlur }: Props) => {
  const translate = useTranslate('credit_card_add.expiration_date')
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormModel>()
  const validationMessage = useFormValidationMessage(errors, 'credit_card_add')
  const fieldValue = watch(Field.ExpirationDate, '')

  function handleDateChange(event: ChangeEvent<HTMLInputElement>) {
    let { value: newValue } = event.target

    const numbers = numbersOnly(newValue)

    if (numbers.length >= MIN_NUMBERS_LENGTH) {
      const parts = numbers.match(/^(\d{2})(\d{2,4})$/)
      parts?.shift()
      newValue = parts?.join(SEPARATOR) ?? newValue
    } else {
      newValue = newValue.replace(/[^0-9/]/g, '')
    }

    setValue(Field.ExpirationDate, newValue)
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    const { key } = event

    if (!/^\d+$/.test(key)) return

    const newValue = fieldValue + key

    if (/^\d$/.test(newValue) && newValue !== '0' && newValue !== '1') {
      event.preventDefault()

      setValue(Field.ExpirationDate, `0${newValue}${SEPARATOR}`)
    } else if (/^\d\d$/.test(newValue)) {
      event.preventDefault()

      const month = parseInt(newValue[0], 10)
      const year = parseInt(newValue[1].substring(0, 2), 10)
      if (year > 2 && month !== 0) {
        setValue(Field.ExpirationDate, `${month}${SEPARATOR}${year}`)
      } else {
        setValue(Field.ExpirationDate, `${newValue}${SEPARATOR}`)
      }
    }
  }

  function validate(value: string) {
    const expiry = parseExpiryDate(value)

    if (!expiry) return translate('errors.invalid')

    if (isExpired(expiry)) return translate('errors.expired')

    return undefined
  }

  return (
    <InputText
      {...register(Field.ExpirationDate, {
        required: true,
        validate,
      })}
      title={translate('title')}
      placeholder={translate('placeholder')}
      maxLength={MAX_LENGTH}
      uncontrolled
      styling={InputText.Styling.Tight}
      onChange={handleDateChange}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
      validation={renderValidation(validationMessage(Field.ExpirationDate))}
    />
  )
}

export default CardExpirationDate
