import { PaymentsProvider } from 'constants/payments'

import adyenTokenizer from './adyen'
import mangopayTokenizer from './mangopay'
import checkoutComTokenizer from './checkout-com'
import payrailsTokenizer from './payrails'

function getTokenizer(provider: PaymentsProvider) {
  switch (provider) {
    case PaymentsProvider.Adyen:
    case PaymentsProvider.AdyenBank:
      return adyenTokenizer
    case PaymentsProvider.Mangopay:
      return mangopayTokenizer
    case PaymentsProvider.CheckoutCom:
      return checkoutComTokenizer
    case PaymentsProvider.Payrails:
      return payrailsTokenizer
    default:
      throw new Error(`Unsupported payment provider for tokenization ${provider}`)
  }
}

export default getTokenizer
