import { CreditCardType } from 'constants/credit-card'
import { numbersOnly } from 'libs/utils/number'

import { CreditCardConfigurationModel } from 'types/models'

import { Field, CreditCard, FormModel } from './types'

export function getConfigFromNumber(
  cardNumber: string,
  cardConfigs: Array<CreditCardConfigurationModel>,
) {
  const parsedCardNumber = numbersOnly(cardNumber)

  return cardConfigs.find(({ cardNumberPatterns }) => {
    return cardNumberPatterns.find(pattern => new RegExp(pattern).test(parsedCardNumber))
  })
}

export function getDefaultConfig(cardConfigs: Array<CreditCardConfigurationModel>) {
  return cardConfigs.find(({ type }) => type === CreditCardType.Unknown)
}

export function isLuhnValid(cardNumber: string) {
  const parsedCardNumber = numbersOnly(cardNumber)

  let sum = Number(parsedCardNumber.slice(-1))
  const totalLength = parsedCardNumber.length
  const parity = totalLength % 2

  for (let index = 0; index < totalLength - 1; index += 1) {
    let digit = Number(parsedCardNumber.charAt(index))
    if (index % 2 === parity) {
      digit *= 2
    }
    if (digit > 9) {
      digit -= 9
    }
    sum += digit
  }

  return sum % 10 === 0
}

export function parseExpiryDate(dateInput: string) {
  const [month, year]: Array<string | undefined> = dateInput.match(/\d{1,4}/g) || []

  const yearFirstDigits = String(new Date().getFullYear()).slice(0, 2)
  const normalizedYear = year?.length === 2 ? `${yearFirstDigits}${year}` : year
  const zeroBasedMonth = Number(month) - 1
  const expiry = new Date(Number(normalizedYear), zeroBasedMonth, 1)

  if (expiry.getFullYear() !== Number(normalizedYear) || expiry.getMonth() !== zeroBasedMonth) {
    return undefined
  }

  const lastDayOfMonth = new Date(expiry.getFullYear(), expiry.getMonth() + 1, 0, 23, 59, 59)

  return lastDayOfMonth
}

export function transformFormToCard(
  form: FormModel,
  config: CreditCardConfigurationModel,
  userSelectedCardBrand: CreditCardType | null,
): CreditCard | null {
  const date = parseExpiryDate(form.expirationDate)

  if (!date) return null

  return {
    brand: userSelectedCardBrand || config.type,
    name: form.name,
    cardNumber: numbersOnly(form.cardNumber),
    cvc: numbersOnly(form.cvc),
    expirationYear: date.getFullYear(),
    expirationMonth: date.getMonth() + 1,
    postalCode: form.postalCode,
  }
}

// Card BIN is the first 6 characters of card number.
export function getCardBrandIdentificator(cardNumber?: FormModel[Field.CardNumber]) {
  const rawCardNumber = numbersOnly(cardNumber)

  return rawCardNumber.slice(0, 6) || null
}

// Card BIN needed for co-branded card identification.
export function getCobrandedCardBrandIdentificator(cardNumber?: FormModel[Field.CardNumber]) {
  const rawCardNumber = numbersOnly(cardNumber)

  if (rawCardNumber.length >= 16) {
    return rawCardNumber.slice(0, 8)
  }

  if (rawCardNumber.length < 16 && rawCardNumber.length >= 6) {
    return rawCardNumber.slice(0, 6)
  }

  return null
}
