export type AdyenAuthState = {
  data: {
    details: Record<string, string>
    paymentData: string
  }
}

type OnAdditionalDetails = {
  (state: AdyenAuthState, element: unknown): void
}

type OnError = {
  (error: AdyenCheckoutError): void
}

// Type based on https://docs.adyen.com/online-payments/web-components/optional-configuration
export type AdyenCheckoutConfig = {
  locale: string
  environment: string
  clientKey: string
  paymentMethodsConfiguration?: any
  onAdditionalDetails?: OnAdditionalDetails
  onError?: OnError
}

export type AdyenComponentInstance = {
  remove(): void
}

type AdyenComponent = {
  mount(target: string | Element): AdyenComponentInstance
}

export type AdyenCheckout = {
  createFromAction(action: AdyenAuthAction, props?: any): AdyenComponent
}

export type AdyenCheckoutCreator = (config: AdyenCheckoutConfig) => Promise<AdyenCheckout>

export enum AdyenAuthActionType {
  ThreeDS2 = 'threeDS2',
  Await = 'await',
}

export enum AdyenAuthActionSubtype {
  Fingerprint = 'fingerprint',
  Challenge = 'challenge',
}

export type AdyenAuthAction = {
  type: AdyenAuthActionType
  subtype: AdyenAuthActionSubtype
  paymentData: string
  paymentMethodType: string
  token: string
}

export type AdyenCheckoutError = {
  errorCode: string
  message: string
}

export type AdyenCheckoutSite = 'test' | 'live'
export type AdyenCheckoutResourceType = 'js' | 'css'

export type AdyenCheckoutResource = {
  type: AdyenCheckoutResourceType
  integrity: string
}

export type AdyenCheckoutResourceConfig = {
  version: string
  resources: Record<AdyenCheckoutSite, Array<AdyenCheckoutResource>>
}

type CreditCard = {
  number?: string
  cvc?: string
  holderName?: string
  expiryMonth?: string
  expiryYear?: string
  generationtime: string
}

type CSEEncryption = {
  encrypt: (creditCard: CreditCard) => string | undefined
}

export type Adyen = {
  createEncryption: (accessKey: string) => CSEEncryption
}
